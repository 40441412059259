import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const SiennicaCmentarzParafialny = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Cmentarz przy kościele i katakumby" />
      <ReturnToPrevPage to={'/gminy/siennica'}>
        Materiały dotyczące gminy Siennica
      </ReturnToPrevPage>
      <Header1>Cmentarz przy kościele i katakumby</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          W przedostatniej dekadzie ubiegłego wieku pisał o nich jeden z
          regionalistów badających dzieje Ziemi Mińskiej Władysław Rudziński. –
          W podziemiach kościoła, w sektorze pod prezbiterium, znajdują się
          trumny Ojców Reformatów, w sektorze pod amboną trumny zmarłych z
          rodziny Dłużewskich, a w sektorze nawy wejściowej do świątyni trumny
          fundatorów Kazimierza i Antoniny, Michała i Elżbiety Rudzińskich,
          Franciszka Rudzińskiego oraz prawdopodobnie innych członków rodziny
          Rudzińskich – napisał on w publikacji zatytułowanej „Rudzińscy Herbu
          Prus III z Ziemi Czerskiej”. Ta pozycja wydawnicza ujrzała światło
          dzienne w 1988 roku. W zamieszczonych w niej informacjach dotyczących
          Siennicy można znaleźć również adekwatne do opisywanego zagadnienia
          odwołanie do ustaleń poczynionych przez dr Mariana Benko. – Naprzeciw
          wejścia do podziemi w najbliższym prezbiterium półprzęśle, pod nawą po
          prawej stronie (od str. gł. ołtarza) krypta rodziny Rudzińskich,
          fundatorów kościoła i klasztoru. Potężne, bogate niegdyś, noszące
          resztki obicia i cennych materii trumny, puste i poprzewracane
          spoczywają na wzniesionym nad poziom podziemia podium. Krypta
          wydzielona z reszty podziemi ścianami, niegdyś zamykała się szerokimi
          drzwiami dwuskrzydłowymi lub kratą, po której pozostały futryny z
          hakami – wskazał w pozostawionym potomnym opisie siennickiego kościoła
          parafialnego Marian Benko.
        </p>
        <p>
          To interesujące miejsce, którego historia wymaga pogłębienia
          zaprezentował również w jednym z artykułów dziennikarz Józef Sadowski.
          – W czasie ostatniej wojny kościół poklasztorny został poważnie
          zniszczony. Z ponad stu trumien znajdujących się w podziemiach
          większość spłonęła. Niektóre zwłoki zakonników zostały sprofanowane.
          Kolejni księża siennickiej parafii porządkowali krypty. Dużą w tym
          zasługę ma obecny proboszcz Kazimierz Sokołowski. W podziemnych
          korytarzach kościoła, wysypanych żółtym piaskiem, spoczywają
          reformaci. W pierwszej krypcie znajduje się 11 trumien. W drugiej jest
          5 ciał zakonników i 3 małe trumienki, w których spoczywają zwłoki
          dzieci rodziny Rudzińskich. Zakonnicy byli chowani w drewnianych
          skrzyniach, których nie przykrywano wiekami. Mikroklimat panujący w
          podziemiach sprawił, że zwłoki uległy samoistnej mumifikacji.
          Zachowały się resztki habitów i szaty zakonne. W Dzień Zmarłych przy
          trumnach zapłoną znicze – podkreślił on w materiale zamieszczonym 2
          listopada 2003 roku na łamach „Tygodnika Siedleckiego”. Jak już
          wspomniano pełniejsze wyświetlenie tego zagadnienia wymaga
          przeprowadzenia kolejnych badań historycznych. Aby obejrzeć wspomniane
          pomieszczenia wypada skontaktować się z kancelarią kościoła
          parafialnego w Siennicy -
          <a
            href="https://parafia-siennica.pl/kontakt"
            target="_blank"
            rel="nofollow"
          >
            https://parafia-siennica.pl/kontakt
          </a>
          .
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/siennica/cmentarz-przy-kosciele-i-katakumby/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: {
        relativePath: { regex: "/siennica/cmentarz-przy-kosciele-i-katakumby/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default SiennicaCmentarzParafialny
